import { useContext, useEffect, useState } from 'react';
import CommonStore from './store/CommonStore';
import 'antd/dist/antd.variable.min.css';
import 'antd/dist/antd.less';
import './app.less';
import { Observer, Provider } from 'mobx-react';
import { ConfigProvider, Spin } from 'antd';
import he from 'antd/lib/locale/he_IL';
import { BrowserRouter } from 'react-router-dom';
import { User } from './types';
import api from './api/api';
import MainLayout from './components/MainLayout';
import GuestRouter from './router/GuestRouter';

const App = () => {
  //
  const storeCommon = useContext(CommonStore);

  //
  const [loaded, setLoaded] = useState(false);

  //
  ConfigProvider.config({
    theme: {
      primaryColor: '#06BCC1',
    },
  });

  //
  useEffect(() => {
    window.addEventListener('storage', (e) => {
      if (!localStorage.getItem('token')) {
        window.location.href = '/login';
      }
    });

    (async () => {
      const resp: User = await api('account');
      if (resp.id) {
        storeCommon.setUser(resp);
      } else {
        const route = window.location.pathname.split('/')[1];
        if (
          ![
            'forgot-password',
            'sign-up',
            'invite',
            'reset-password',
            'login',
          ].includes(route)
        )
          window.location.replace('/login');
      }
      setLoaded(true);
    })();
  }, []);

  return (
    <Provider storeCommon={storeCommon}>
      <BrowserRouter>
        <ConfigProvider locale={he} direction='rtl'>
          <Observer>
            {() => {
              if (loaded) {
                if (storeCommon.user) {
                  return <MainLayout />;
                }
                return <GuestRouter />;
              }

              return (
                <div
                  style={{
                    minHeight: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Spin size='large' />
                </div>
              );
            }}
          </Observer>
        </ConfigProvider>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
