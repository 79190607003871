const he = {
  translation: {
    help: 'מידע',

    //
    '{{field}} must be between {{min}} and {{max}':
      '{{field}} חייב להיות בין {{min}} ל-{{max}',
    '{{field}} must be between {{min}} and {{max}} characters':
      '{{field}} חייב להיות בין {{min}} ל-{{max}} תווים',
    '{{field}} must be correct email address':
      '{{field}} חייבת להיות כתובת דוא"ל נכונה',
    '{{field}} must be correct ID': '{{field}} חייב להיות מזהה נכון',
    '{{field}} must be correct mobile phone number':
      '{{field}} חייב להיות מספר טלפון נכון',
    '{{field}} must be grater {{min}} characters':
      '{{field}} חייב להיות גדול מ{{min}} תווים',
    '{{field}} must be grater then {{min}}':
      '{{field}} חייב להיות גדול מ{{min}}',
    '{{field}} must be less {{max}} characters':
      '{{field}} חייב להיות פחות {{max}} תווים',
    '{{field}} must be less then {{max}}':
      '{{field}} חייב להיות פחות מ-{{max}}',
    '{field}} must be correct phone number':
      '{field}} חייב להיות מספר טלפון נכון',
    '45 days trial period': 'תקופת ניסיון של 45 ימים',
    Сommercial: 'מסחרי',
    Сontinue: 'המשך',
    Agencies: 'סוכנויות',
    Active: 'פעיל',
    active: 'פעיל',
    Address: 'רחוב, מס׳ בית',
    Add: 'להוסיף',
    'Add agent': 'הוסף סוכן',
    'Add client': 'הוסף לקוח',
    'Add Contact': 'הוסף איש קשר',
    'Add logo': 'הוסף לוגו',
    'Add new': 'הוסף חדש',
    'Add new property': 'הוסף נכס חדש',
    'Add photo': 'הוסף תמונה',
    'Add property': 'הוסף נכס',
    'Add task': 'הוסף משימה',
    Additionally: 'בנוסף',
    AddTask: 'הוסף משימה',
    'Advertising Property Description': 'תאור נכס לפרסום (מוצג ללקוח)',
    Mine: 'שלי',
    Newest: 'הכי חדש',
    Agency: 'סוכנות',
    'Agency name': 'שם הסוכנות',
    Agent: 'סוכן',
    Agents: 'סוכנים',
    All: 'את כל',
    and: 'ו',
    'Add contact': 'הוספת איש קשר',
    Alphabetical: 'סדר אלף ביתי',
    Tenant: 'שוכר',
    Landlord: 'משכיר',
    Buyer: 'קונה',
    'Buyer Seller': 'קונה - מוכר',
    Apartments: 'דירות',
    Apply: 'הירשם',
    Apt: 'דירה',
    Area: 'שטח',
    'Area m²': 'שטח מ"ר',
    'Area m²__1': 'שטח מ"ר',
    'Associate with buyer': 'שייך לקונה',
    Back: 'חזור',
    Balcony: 'מרפסת שמש',
    Building: 'בנין',
    Buy: 'לִקנות',
    Buyers: 'קונים',
    'By clicking the create account button, I accept the':
      'על ידי לחיצה על כפתור צור חשבון, אני מקבל את',
    Calendar: 'יומן',
    Calls: 'שיחות',
    calls: 'שיחות',
    Cancel: 'בטל',
    'Cancel subscription': 'בטל רישום',
    'Change number': 'עדכן מספר',
    'Change Status': 'עדכן סטטוס',
    'Choose Plan': 'בחר תוכנית',
    'Choose Your Plan': 'בחר את התוכנית שלך',
    'Choose Your Role': 'בחר את התפקיד שלך',
    Cities: 'ערים',
    'No calls': 'אין שיחות נכנסות',
    Menu: 'תפריט',
    City: 'עיר',
    Clear: 'נקה',
    Completed: 'הושלם',
    Contact: 'איש קשר',
    'Contact matches': 'התאמות ללקוח',
    Contacts: 'אנשי קשר',
    'Create a new agency': 'צור סוכנות חדשה',
    'Create account': 'יצירת חשבון',
    'Create Account': 'יצירת חשבון',
    Current: 'נוכחי',
    Customers: 'לקוחות',
    'Date of leave': 'תאריך פינוי',
    Description: 'תיאור',
    Dial: 'חייג',
    'Master bedroom': 'יחידת הורים',
    Done: 'בוצע',
    Edit: 'לַערוך',
    'Edit Contact': 'ערוך איש קשר',
    'Edit Property': 'ערוך נכס',
    'Edit Request': 'ערוך בקשה',
    Elevator: 'מַעלית',
    'Elevator above second floor': 'מעלית מעל הקומה השנייה',
    Email: 'אימייל',
    'Enter the invitation code': 'הזן את קוד ההזמנה',
    'Enter Verification Code': 'הכנס קוד אימות',
    'Enter phone number': 'הזן מספר טלפון',
    'Enter your phone number to log in': 'הזן את מספר הטלפון שלך כדי להיכנס',
    Filters: 'מסננים',
    'First name': 'שם פרטי',
    Floor: 'קומה',
    floor: 'קומה',
    'For investment': 'להשקעה',
    'For living': 'למגורים',
    Fr: 'שישי',
    from: 'מ',
    'Has an elevator': 'יש מעלית',
    'Have matches': 'יש התאמות',
    'Highest price': 'המכיר הכי יקר',
    Home: 'בית',
    House: 'בית פרטי',
    'Incoming Calls': 'שיחות נכנסות',
    'Incoming calls': 'שיחות נכנסות',
    'Insert a link to a YouTube video': 'הוסף קישור לסרטון יוטיוב',
    'Invalid code': 'קוד לא תקין',
    ipsum: 'איפסום',
    'Join an agency': 'הצטרף לסוכנות',
    'Last call': 'שיחה אחרונה',
    'Last name': 'שם משפחה',
    'Last week': 'בשבוע שעבר',
    'Last month': 'בחודש שעבר',
    'Last year': 'בשנה שעברה',
    'Launch camera': 'הפעל מצלמה',
    'Log out': 'להתנתק',
    'Login to the app': 'התחבר לאפליקציה',
    Lorem: 'לורם',
    'Lowest price': 'המחיר הנמוך ביותר',
    'm²': 'מ"ר',
    Madlan: 'מדלן',
    Mamad: 'ממ"ד',
    Matches: 'התאמות',
    Missed: 'החמצה',
    MLS: 'MLS',
    Mo: 'שני',
    month: 'חודש',
    Monthly: 'חודשי',
    More: 'יותר',
    'Most recent': 'הכי עדכני',
    Mute: 'להשתיק',
    'My profile': 'הפרופיל שלי',
    Neighborhood: 'שכונה',
    'New search': 'חיפוש חדש',
    'New building': 'בניין חדיש',
    No: 'לא',
    'No account?': 'אין חשבון?',
    'No tasks': 'אין משימות',
    'Not client': 'לא לקוח',
    'Not the first': 'לא קומה ראשונה',
    'Not the last': 'לא קומה אחרונה',
    Notes: 'הערות',
    'New note': 'תגובה חדשה',
    of: 'מתוך',
    'Office Notes': 'הערות משרד',
    Ok: 'בסדר',
    'Only MLS': 'רק MLS',
    'Only RealEstate': 'רק נדל"ן',
    optional: 'אופציונאלי',
    Owner: 'בעלים',
    Parking: 'חניה',
    Payment: 'תשלום',
    'per m²': 'למ"ר',
    'Per month': 'לחודש',
    Annually: 'בחיוב שנתי',
    Phone: 'מכשיר טלפון',
    'Photo and video': 'תמונה ווידאו',
    'Please enter valid Email address': 'נא להזין כתובת אימייל חוקית',
    'Please enter your {{field}}': 'נא להזין את ה-{{field}} שלך',
    'Please enter your address': 'נא להזין כתובת',
    'Please enter your Agency Name': 'נא להזין את שם המשרד',
    'Please enter your apt': 'נא להזין מס דירה',
    'Please enter your area': 'נא להזין שטח',
    'Please enter your building': "נא להזין מס' בניין",
    'Please enter your city': 'נא להזין עיר',
    'Please enter your First Name': 'נא להזין שם פרטי',
    'Please enter your Email': 'נא להזין כתובת דוא״ל',
    'Please enter your floor': 'נא להזין קומה',
    'Please enter your floor count': 'נא להזין מספר הקומות בבניין',
    'Please enter your Last Name': 'נא להזין שם משפחה',
    'Please enter your phone': "נא להזין מס' טלפון",
    'Please enter your price': 'נא להזין מחיר',
    'Please enter your rooms': "נא להזין מס' חדרים",
    'Please enter your seller': 'נא להזין פרטי המוכר',
    'Please enter your street': 'נא להזין שם רחוב',
    'Use WhatsApp Business': 'להשתמש בווטסאפ עסקי?',
    Price: 'מחיר',
    'privacy policy': 'מדיניות פרטיות',
    Profile: 'פּרוֹפִיל',
    'Profile saved successfull': 'הפרופיל נשמר בהצלחה',
    Properties: 'נכסים',
    Property: 'נכס',
    'Property matches': 'התאמות נכסים',
    'Property type': 'סוג נכס',
    'Purpose of purchase': 'מטרת הרכישה',
    recommended: 'מומלץ',
    'Real estate property': 'נכס מקרקעין',
    'Registered at': 'נרשם ב',
    Rent: 'השכרה',
    'Registered customers': 'לקוחות רשומים',
    Request: 'בקשה',
    'Resend code': 'שלח קוד שוב',
    Repaired: 'משופץ',
    Rooms: 'חדרים',
    rooms: 'חדרים',
    Sa: 'שבת',
    Save: 'שמור',
    Search: 'לחפש',
    Select: 'בחר',
    'Select a user': 'בחר משתמש',
    'Select date': 'בחר תאריך',
    'Select from gallery': 'בחר מתוך הגלריה',
    'Select the account type': 'בחר את סוג החשבון',
    'Select Type': 'סוג חשבון',
    'Select user': 'בחר משתמש',
    'Select your role': 'בחר את התפקיד שלך',
    Sell: 'מכירה',
    Seller: 'מוכר',
    'send Verification code': 'שלח קוד אימות',
    Settings: 'הגדרות',
    Show: 'להציג',
    'Show in call': 'הצג בשיחה',
    'Show in MLS': 'הצג ב-MLS',
    'Show in agency': 'נכסי המשרד',
    'Show on call preview': 'הצג בחלונית שיחה נכנסת',
    'Some welcome text here': 'קצת טקסט ברוך הבא',
    Speeker: 'דובר',
    Spidil: 'ספידיל',
    Status: 'סטָטוּס',
    Street: 'רְחוֹב',
    Su: 'ראשון',
    'No elevator': 'ללא מעלית',
    Advertised: 'פורסם ב-',
    // Advertised: 'פורסם',
    Subscription: 'מִנוּי',
    Success: 'הַצלָחָה',
    Tasks: 'משימות',
    Terms: 'תנאים',
    'terms and conditions': 'תנאים',
    Th: 'חמישי',
    'The image should be smaller and will be reduced to 300 KB!':
      'התמונה צריכה להיות קטנה יותר ותצטמצם ל-300 KB!',
    'The invitation code was sent by your supervisor via SMS along with a link to this application':
      'קוד ההזמנה נשלח על ידי הממונה שלך באמצעות SMS יחד עם קישור לאפליקציה זו',
    Title: 'כותרת',
    to: 'עד',
    Today: 'היום',
    'Total matches': 'סה"כ התאמות',
    Tu: 'שלישי',
    Type: 'סוג',
    'Types of contacts': 'סוגי אנשי קשר',
    Unknown: 'לא ידוע',
    'up to': 'עד ל',
    Verify: 'תאשר',
    Video: 'וִידאוֹ',
    View: 'הצג',
    'View other plans': 'צפה בתוכניות אחרות',
    We: 'רביעי',
    'Welcome!': 'ברוך הבא!',
    'Without matches': 'בלי התאמות',
    'Yad 2': 'יד 2',
    year: 'שנה',
    Yes: 'כן',
    YES: 'כן',
    // Phones: '',
    Yesterday: 'אתמול',
    'You can create your own agency or join an existing agency':
      'אתה יכול ליצור סוכנות משלך או להצטרף לסוכנות קיימת',
    'You only work with your personal clients and deals':
      'אתה עובד רק עם הלקוחות האישיים והעסקאות שלך',
    'Your code': 'הקוד שלך',
    'Your phone number': 'מספר הטלפון שלך',
    'You can add new agents, view all the properties and clients of your agency':
      'באפשרותך לצרף סוכנים חדשים ולראות את כל הנכסים וכל הלקוחות בסוכנות',

    //////////////////
    "Check your SMS messages. We'му sent you the code at":
      'קוד אמות  נשלח בהודעת סמס למספר טלפון',

    '{{field}} must be {{length}} characters':
      '{{field}} חייב להיות {{length}} תווים',
    'Select seller': 'להוסיף מוכר',
    'Add seller': 'הוסף מוכר',

    Apartment: 'דירה',
    'Garden apartment': 'דירת גן',
    'Private house/cottage': 'בית פרטי/קוטג',
    'Roof/penthouse': 'גג/פנטהוז',
    Duplex: 'דופלקס',

    'Initial contact': 'קשר ראשוני',
    Discussion: 'משא ומתן',
    'Decision making': 'קבלת החלטות',
    // 'Contract discussion': 'מסע ומתן',
    'Closed — won': 'סגור, מוצלח',
    'Closed — lost': 'סגור, נכשל',
    'Invite code': 'קוד חיבור סוכן',
    // optional: '',
    // Flexible: '',
    'MLS Notes': 'תנאי השת״פ',

    Meeting: 'פגישה',
    Task: 'משימה',
    Reminer: 'תזכורת',
    Follow: 'פולואפ',
    'Phone call': 'טלפון',
    Urgent: 'דחוף',
    Marketing: 'שיווק',
    Advertising: 'פרסום',
    'Customer arrival': 'מקור הגעת לקוח',
    'Select from list': 'בחר מרשימה',

    'Showing {{start}} - {{end}} of {{total}} records':
      'הוצג {{start}} - {{end}} מי {{total}} רשומות',
    Checked: 'סמן שנבדק',
    'Which card does this agent pay with?': 'באיזה כרטיס משלם הסוכן הזה?',
    'Activity Areas': 'אזורי פעילות',
    'Messard, Street, House Number, City': 'מסרד,רחוב,מספר בית,עיר',

    "You don't have active terms and conditions": 'אין לך תנאים והגבלות פעילים',
    "You don't have an active privacy policy": 'אין לך מדיניות פרטיות פעילה',

    completed: 'הושלם',
    'in progress': 'בתהליך',
    total: 'סהייכ',
    new: 'חדש',
    example: 'דוגמא',
    Visible: 'נראה',
    Position: 'עמדה',
    Videos: 'סרטונים',
    'Video tutorials': 'הדרכות וידאו',
    'Add new video': 'הוסף סרטון חדש',
    'payment date': 'תאריך תשלום',

    '{{days}} trial days left': 'נותרו {{days}} ימי ניסיון',
    'Trial days have expired': 'פג תוקף ימי הניסיון',

    'To change your plan, contact us by email':
      'כדי לשנות את תוכנית התוכנית השנתית, צרו איתנו קשר במייל',
    'New card': 'כרטיס חדש',
    Pending: 'בהמתנה',
    Suspend: 'מושעה',
    'Enter credit card': 'הזן כרטיס אשראי',
    'Enter cvc': 'הזן cvc',
    'Enter expiry': 'הזן תאריך תפוגה',
    'The selected status deletes the agent, all his information is transferred to the office manager, this action cannot be canceled. Are you sure?':
      'פעולה מוחקת את הסוכן, כל המידע שלו עובר למנהל המשרד, פעולה זו לא ניתנת לביטול. האם אתה בטוח?',
    'Custom status': 'סטטוס מותאם אישית',
    'The seller agreed to lower the price. We expect an amended contract from the seller':
      'המוכר הסכים להוריד את המחיר. אנו מצפים לחוזה מתוקן מהמוכר',
    'Your plan is already in the process of cancellation':
      'התוכנית שלך כבר נמצאת בתהליך ביטול',
    'End date:': 'תאריך סיום:',
    'Property has been raised or created within the last 24 hours':
      'נכס הועלה או נוצר במהלך 24 השעות האחרונות',
    'Confirm status': 'אשר סטטוס',
    'Change status': 'לשנות סטטוס',

    'Create with another agent': 'צור עם סוכן אחר',
    'Agent name': 'שם סוכן',
  },
};

export default he;
