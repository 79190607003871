import { Badge, Menu, Space } from 'antd';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import {
  UserOutlined,
  BankOutlined,
  EnterOutlined,
  DashboardOutlined,
  DollarCircleOutlined,
  TeamOutlined,
  ContainerOutlined,
  PartitionOutlined,
  PlayCircleOutlined,
  UserSwitchOutlined,
  CalendarOutlined,
  DollarOutlined,
  PhoneOutlined,
  SafetyCertificateOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import CommonStore from '../../store/CommonStore';
import { AgentRole } from '../../types';
import { Link, useLocation } from 'react-router-dom';
import api from '../../api/api';

type MenuType = {
  key: string;
  label: string | ReactNode;
  icon: ReactNode;
  items?: { key: string; label: string | ReactNode; icon: ReactNode }[];
};

const { SubMenu, Item } = Menu;

const NavigationBar: React.FC<{ theme: 'dark' | 'light' }> = ({ theme }) => {
  //
  const { t } = useTranslation();

  //
  const storeCommon = useContext(CommonStore);

  //
  const location = useLocation();

  //
  const [moderationQnt, setModerationQnt] = useState(0);

  //
  const menu: { [key: number]: MenuType[] } = {
    [AgentRole.ROLE_OWNER]: [
      {
        key: '',
        label: t('Dasboard'),
        icon: <DashboardOutlined />,
      },
      {
        key: 'properties',
        label: t('Properties'),
        icon: <BankOutlined />,
      },
      // {
      //   key: 'requests',
      //   label: t('Requests'),
      //   icon: <SolutionOutlined />,
      // },
      {
        key: 'contacts',
        label: t('Contacts'),
        icon: <UserSwitchOutlined />,
      },

      {
        key: 'agents',
        label: t('Agents'),
        icon: <TeamOutlined />,
      },
      {
        key: 'tasks',
        label: t('Tasks'),
        icon: <CalendarOutlined />,
      },
      // {
      //   key: 'admins',
      //   label: t('Admins'),
      //   icon: <UserOutlined />,
      // },
      {
        key: 'incomingCalls',
        label: t('Calls'),
        icon: <PhoneOutlined />,
      },
    ],
    [AgentRole.ROLE_PARTNER]: [
      {
        key: '',
        label: t('Dasboard'),
        icon: <DashboardOutlined />,
      },
      {
        key: 'properties',
        label: t('Properties'),
        icon: <BankOutlined />,
      },
      // {
      //   key: 'requests',
      //   label: t('Requests'),
      //   icon: <SolutionOutlined />,
      // },
      {
        key: 'contacts',
        label: t('Contacts'),
        icon: <UserSwitchOutlined />,
      },

      {
        key: 'agents',
        label: t('Agents'),
        icon: <TeamOutlined />,
      },
      {
        key: 'tasks',
        label: t('Tasks'),
        icon: <CalendarOutlined />,
      },
      // {
      //   key: 'admins',
      //   label: t('Admins'),
      //   icon: <UserOutlined />,
      // },
      {
        key: 'incomingCalls',
        label: t('Calls'),
        icon: <PhoneOutlined />,
      },
    ],
    [AgentRole.ROLE_AGENT]: [
      {
        key: '',
        label: t('Dasboard'),
        icon: <DashboardOutlined />,
      },
      {
        key: 'properties',
        label: t('Properties'),
        icon: <BankOutlined />,
      },
      // {
      //   key: 'requests',
      //   label: t('Requests'),
      //   icon: <SolutionOutlined />,
      // },
      {
        key: 'contacts',
        label: t('Contacts'),
        icon: <UserSwitchOutlined />,
      },
      {
        key: 'tasks',
        label: t('Tasks'),
        icon: <CalendarOutlined />,
      },
      {
        key: 'incomingCalls',
        label: t('Calls'),
        icon: <PhoneOutlined />,
      },
    ],
    [AgentRole.ROLE_ADMIN]: [
      {
        key: '',
        label: t('Dasboard'),
        icon: <DashboardOutlined />,
      },
      {
        key: 'agencies',
        label: t('Agencies'),
        icon: <ContainerOutlined />,
      },
      {
        key: 'payments',
        label: t('Payments'),
        icon: <DollarOutlined />,
      },
      {
        key: 'agents',
        label: t('Agents'),
        icon: <TeamOutlined />,
      },
      {
        key: 'properties',
        label: t('Properties'),
        icon: <BankOutlined />,
      },
      // {
      //   key: 'requests',
      //   label: t('Requests'),
      //   icon: <SolutionOutlined />,
      // },
      {
        key: 'contacts',
        label: t('Contacts'),
        icon: <UserSwitchOutlined />,
      },
      // {
      //   key: 'agencies',
      //   label: t('Agencies'),
      //   icon: <ContainerOutlined />,
      // },
      // {
      //   key: "tasks",
      //   label: t("Tasks"),
      //   icon: <CalendarOutlined />,
      // },
      // {
      //   key: 'admins',
      //   label: t('Admins'),
      //   icon: <UserOutlined />,
      // },
      {
        key: 'incomingCalls',
        label: t('Calls'),
        icon: <PhoneOutlined />,
      },
      {
        key: 'terms',
        label: t('Terms and Privacy'),
        icon: <SafetyCertificateOutlined />,
      },
      {
        key: 'videos',
        label: t('Videos'),
        icon: <PlayCircleOutlined />,
      },
    ],
  };

  const activeRoute = location.pathname.split('/')[1] || '';

  //
  const openedKey = () => {
    let ret = '';
    if (storeCommon.user) {
      menu[storeCommon.user.role].forEach((item) => {
        const search = item.items?.find((sub) => {
          return sub.key.split('/')[0] === activeRoute;
        });
        if (search) {
          ret = item.key;
          return;
        }
      });
    }
    return ret;
  };

  // //
  // useEffect(() => {
  //   (async () => {
  //     if (storeCommon.user?.role !== AdminRoles.SUPERADMIN) return;

  //     const respSynagogues = await api(
  //       "synagogues?per-page=100&page=1&sort=id&filter[and][0][or][][synagogue.status]=10"
  //     );
  //     setModerationQnt(respSynagogues.total);
  //   })();
  // }, [location.pathname]);

  return (
    <Menu
      // onClick={this.handleClick}
      id={`menu-${storeCommon.user?.id}`}
      style={{ width: 256 }}
      mode='inline'
      theme={theme}
      defaultOpenKeys={[openedKey()]}
      defaultSelectedKeys={[activeRoute]}
      key={`menu-${activeRoute}`}
    >
      {storeCommon.user &&
        menu[storeCommon.user.role].map((item, index) => {
          return (
            <React.Fragment key={index}>
              {!item.items && (
                <Item icon={item.icon} key={item.key}>
                  <Link to={`/${item.key}`}>{item.label}</Link>
                </Item>
              )}
              {item.items && (
                <SubMenu key={item.key} icon={item.icon} title={item.label}>
                  {item.items.map((sub) => {
                    return (
                      <Item icon={sub.icon} key={sub.key}>
                        <Link to={`/${sub.key}`}>{sub.label}</Link>
                      </Item>
                    );
                  })}
                </SubMenu>
              )}
            </React.Fragment>
          );
        })}
    </Menu>
  );
};

export default NavigationBar;
