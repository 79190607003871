// export type Admin = {
//   id: number;
//   username: string;
//   email: string;
//   status: number;
//   role: AdminRoles;
//   phone: string;
// };

// export enum AdminRoles {
//   SUPERADMIN = 100,
//   SYNAGOGUE = 10,
//   PARTNER = 1,
// }

export type File = {
  id: number;
  uid: string;
  extention: string;
  size: number;
  title: string;
  name: string;
  url: string;
  field: string;
};
export enum AgencyPlan {
  PLAN_NOT_SET = 0,
  PLAN_MONTHLY = 1,
  PLAN_ANNUALLY = 12,
  PLAN_FREE = 100,
}

export enum AgencyStatus {
  TRIAL = 1,
  ACTIVE = 10,
  SUSPENDED = -1,
}

export type Agency = {
  code: number;
  created_at: number;
  id: number;
  logo: string | null;
  owner_id: number;
  payment_date: number | null;
  phone: string;
  plan: AgencyPlan;
  status: AgencyStatus;
  title: string;
  agents?: User[];
  // updated_at: number

  properties_count?: number;

  trial?: number;
  canceled_date?: string | null;
  cancelation_reason?: string | null;
  payment_method?: number;
  next_payment?: string | null;
};

export type User = {
  id: number;
  fname: string;
  lname: string;
  //city: number;
  cities: number[];
  address: string;
  phone: string;
  email: string | null;
  status: AgentStatus;
  role: AgentRole;
  avatar: string | null;
  agency_id: number;
  agency: Agency | null;

  is_admin: number;

  created_at?: number;
  payment_method?: number;
};

export enum AgentStatus {
  STATUS_ACTIVE = 10,
  STATUS_SUSPEND = -1,
  STATUS_PENDING = 9,
}

export enum AgentRole {
  ROLE_NO_ROLE = 0,
  ROLE_AGENT = 10,
  ROLE_PARTNER = 90,
  ROLE_OWNER = 100,
  ROLE_ADMIN = 1000,
}

export type Contact = {
  id: number;
  fname: string;
  lname: string;
  phones: string[];
  email: string | null;
  role: ContactRole;
  avatar: string | null;
  // status: PropertyStatus.STATUS_INITIAL;
  city?: number;
  address?: string;
  request?: Request;
  property?: Property;
  matches_count?: number;
  agent: User;
  agency: Agency;

  matches?: Property[];
  last_call?: Call;
  created_at?: number;
};

export enum ContactRole {
  ROLE_BUYER = 10,
  ROLE_SELLER = 20,
  ROLE_TENANT = 30,
  ROLE_LANDLORD = 40,
  ROLE_BUYER_SELLER = 100,
}

export type Term = {
  content: string;
  title: string;
};

export type ErrorType = {
  field: string;
  message: string;
};

export type Call = {
  name?: string;
  date: number;
  status: CallStatus;
  phone: string;
  agent?: User;
  contact?: User;
};

export enum CallStatus {
  STATUS_MISSED = -1,
  STATUS_INCOME = 1,
}

export type Property = {
  id: number;
  type: PropertyType;
  city: number;
  neighborhood: string;
  prop_type: PropertyFilterType;
  street: string;
  building: string;
  apt: string;
  area: number;
  price: number;
  price_m: number;
  rooms: number;
  floor: number;
  floor_count: number;
  elevator: PropertyFilterElevator;
  leave_date: string;
  leave_flex: number;
  parking: boolean;
  mamad: boolean;
  p_number: number;
  balcony: number;
  master_bedroom: number;
  advertised: string[];
  show_in_call: boolean;
  show_in_mls: boolean;
  show_in_agency: boolean;
  mls_notes: string;
  notes: string;
  status: PropertyStatus;
  description: string;
  images: string[];
  video: string;
  seller_id: number;
  seller?: Contact;
  buyers?: Contact[];
  // purpose: PropertyFilterPurpose;
  agent?: User;
  // adencyId: number;
  matches_count?: number;
  matches?: Request[];
  contact?: Contact;
  last_call?: Call;
  location?: {
    lat: number | null;
    lng: number | null;
  };
  custom_status: string;
  created_at?: number;
  updated_at?: number;
  purpose?: string;

  //  field that shows last property lift by button
  bounced_up?: number;

  repaired: boolean;
  new_building: boolean;
};

export enum PropertyStatus {
  STATUS_INITIAL = 10,
  STATUS_DISCUSSION = 20,
  STATUS_DICISION = 30,
  //   STATUS_CONTRACT_DISC = 40,
  STATUS_CLOSET_WON = 50,
  STATUS_CLOSET_LOST = 1,
}

export enum PropertyType {
  TYPE_BUY = 5,
  TYPE_SELL = 5,
  TYPE_RENT = 10,
}

export type PropertyFilter = {
  type: PropertyType;
  city: number[];
  neighborhood: string[];
  purpose: PropertyFilterPurpose[];
  prop_type: PropertyFilterType[];
  // area: number[];
  area: (number | null)[];
  price: (number | null)[];
  rooms: number[];
  floor: (number | null)[];
  is_first: boolean;
  is_last: boolean;
  elevator: number[];
  additional: { [key: string]: boolean };
  notes: string;
  badge: number;
  mine_mls: 'all' | 'mine' | 'mls' | 'agency';
  advertised: string[];
};

export enum PropertyFilterPurpose {
  FOR_LIVING = 1,
  FOR_INVEST = 10,
  FOR_OFFICE = 100,
}

export enum PropertyFilterType {
  TYPE_APARTMENTS = 1,
  TYPE_HOUSE = 10,
  TYPE_COMMERCIAL = 100,
  TYPE_GARDEN_APARTMENTS = 200,
  TYPE_PENTHOUSE = 300,
  TYPE_DUPLEX = 400,
}

export enum PropertyFilterElevator {
  ELEVATOR_NOT_EXIST = 0,
  ELEVATOR_EXIST = 10,
  ELEVATOR_FROM_2ND_FLOOR = 20,
}

export const emptyProperty = {
  id: 0,
  type: PropertyType.TYPE_SELL,
  city: 0,
  neighborhood: '',
  prop_type: PropertyFilterType.TYPE_APARTMENTS,
  street: '',
  building: '',
  apt: '',
  area: 0,
  price: 0,
  price_m: 0,
  rooms: 0,
  floor: 0,
  floor_count: 0,
  elevator: PropertyFilterElevator.ELEVATOR_NOT_EXIST,
  leave_date: '',
  parking: false,
  mamad: false,
  p_number: 0,
  balcony: 0,
  master_bedroom: 0,
  advertised: [],
  show_in_call: false,
  show_in_mls: false,
  show_in_agency: false,
  mls_notes: '',
  notes: '',
  status: PropertyStatus.STATUS_INITIAL,
  description: '',
  images: [],
  video: '',
  seller_id: 0,
  // seller: User;
  location: {
    lat: null,
    lng: null,
  },
  custom_status: '',
  leave_flex: 0,

  repaired: false,
  new_building: false,
};

export const emptyFilter = {
  type: null,
  city: [],
  neighborhood: [],
  purpose: [],
  prop_type: [],
  area: [null, null],
  price: [null, null],
  rooms: [],
  floor: [null, null],
  is_first: false,
  is_last: false,
  elevator: [],
  additional: {
    parking: false,
    mamad: false,
    master_bedroom: false,
    balcony: false,
    repaired: false,
    new_building: false,
    // p_number: false,
  },
  notes: '',
  badge: 0,
  mine_mls: 'all',
  advertised: [],
};

export type Request = {
  id: number;
  type: PropertyType;
  city: number[];
  neighborhood: string;
  purpose: PropertyFilterPurpose[];
  prop_type: PropertyFilterType[];
  street: string;
  area_from: number;
  area_to: number;
  price_from: number;
  price_to: number;
  floor_from: number;
  floor_to: number;
  rooms: number[];
  // floorCount: number;
  is_first: boolean;
  is_last: boolean;
  elevator: PropertyFilterElevator[];
  leave_date: string;
  parking: boolean;
  mamad: boolean;
  p_number: number;
  balcony: number;
  master_bedroom: number;
  advertised: string[];
  search_in_mls: boolean;
  notes: string;
  status: PropertyStatus;
  description: string;
  images: string[];
  video: string;
  client_id: number;
  contact?: Contact;
  matches?: Property[];
  last_call?: Call;
  // sellerId: number;
  // buyer: Contact;
  // agentId: number;
  // adencyId: number;
  location?: {
    lat: number | null;
    lng: number | null;
  };
  custom_status: string;

  matches_search?: Property[];

  radius: number;

  repaired: boolean;
  new_building: boolean;
  notes_history?: string[];
};

export const emptyRequest: Request = {
  id: 0,
  type: PropertyType.TYPE_BUY,
  city: [],
  neighborhood: '',
  purpose: [],
  prop_type: [],
  street: '',
  area_from: 0,
  area_to: 0,
  price_from: 0,
  price_to: 0,
  floor_from: 0,
  floor_to: 0,
  rooms: [],
  // floorCount: number;
  is_first: false,
  is_last: false,
  elevator: [],
  leave_date: '',
  parking: false,
  mamad: false,
  p_number: 0,
  balcony: 0,
  master_bedroom: 0,
  advertised: [],
  search_in_mls: false,
  notes: '',
  status: PropertyStatus.STATUS_INITIAL,
  description: '',
  images: [],
  // sellerId: number,
  // seller: User,
  // buyer: Contact,
  // agentId: number,
  // adencyId: number,
  location: {
    lat: null,
    lng: null,
  },
  custom_status: '',
  video: '',
  client_id: 0,
  radius: 1.0,

  repaired: false,
  new_building: false,
};

export type ContactFilter = {
  contact_type: ContactRole[];
  matches: boolean | undefined;
  city: number[];
  neighborhood: string[];
  purpose: PropertyFilterPurpose[];
  prop_type: PropertyFilterType[];
  // area: number[];
  area: (number | null)[];
  price: (number | null)[];
  rooms: number[];
  floor: (number | null)[];
  is_first: boolean;
  is_last: boolean;
  elevator: number[];
  additional: { [key: string]: boolean };
  notes: string;
  badge: number;
};

export const emptyContactFilter = {
  contact_type: [],
  matches: undefined,
  city: [],
  neighborhood: [],
  purpose: [],
  prop_type: [],
  area: [null, null],
  price: [null, null],
  rooms: [],
  floor: [null, null],
  is_first: false,
  is_last: false,
  elevator: [],
  additional: {
    parking: false,
    mamad: false,
    master_bedroom: false,
    balcony: false,
    repaired: false,
    new_building: false,
    //p_number: false,
  },
  notes: '',
  badge: 0,
};

export type Task = {
  id: number;
  title: string;
  date: Date;
  time: string;
  type?: TaskType;
  notes: string;
  created_at?: number;
  updated_at?: number;
  agent_id?: number;
  agency_id?: number;
  status: TaskStatus;
  request_id?: number;
  contact?: Contact;
};

export enum TaskType {
  TYPE_MEETING = 10,
  TYPE_TASK = 20,
  TYPE_REMINDER = 30,
  TYPE_FOLLOW = 40,
  TYPE_PHONE = 50,
  TYPE_URGENT = 60,
  TYPE_MARKETING = 70,
  TYPE_ADVERTISING = 80,
}

export enum TaskStatus {
  STATUS_CURRENT = -1,
  STATUS_COMPLETED = 1,
}

/////////////////////////////////////////
export type PhoneCall = {
  name: string | null;
  callUUID: string;
  handle: string;
};
export type Address = {
  route?: string;
  locality?: string;
  administrative_area_level_1?: string;
  location?: {
    lat: number | null;
    lng: number | null;
  };
};
export interface UserAnswer extends User {
  error?: ErrorType[];
}

export type Card = {
  TranzilaTK: string;
  cardtype: number;
  created_at: number;
  cvv: string;
  expdate: string;
  id: number;
  last: string;
  user_id: number;
};

export type Payment = {
  agency: Agency;
  agency_id: number;
  agent: User | null;
  agent_id: number | null;
  amount: number;
  date: string;
  id: number;
  plan: number;
};

export type TermType = {
  id: string;
  title: string;
  content: string;
  date: number;
  type: number;
  status: number;
};

export enum TermsType {
  PRIVACY = 10,
  TERMS = 100,
}

export type Video = {
  id: number;
  title: string;
  url: string;
  position?: number;
  visible?: number;
  created_at?: number;
};

export type Match = {
  id: number;
  property_id: number;
  request_id: number;
  status: number;
  property_buyer?: number;
};
