import { Button, Modal, Result, Spin } from 'antd';
import { lazy, Suspense, useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import CommonStore from '../store/CommonStore';
import { AgentRole, AgentStatus } from '../types';

// LAZY LOAD COMPONENTS
const Dashboard = lazy(() => import('../pages/Dashboard'));

const Admins = lazy(() => import('../pages/Admins'));
const EditAdmin = lazy(() => import('../pages/Admins/EditAdmin'));

const Agents = lazy(() => import('../pages/Agents'));
const EditAgent = lazy(() => import('../pages/Agents/EditAgent'));

const Agencies = lazy(() => import('../pages/Agencies'));
const Payments = lazy(() => import('../pages/Payments'));
const EditAgency = lazy(() => import('../pages/Agencies/EditAgency'));

const Properties = lazy(() => import('../pages/Properties'));
const ViewProperty = lazy(() => import('../pages/Properties/PropertyView'));
const EditProperty = lazy(() => import('../pages/Properties/EditProperty'));

const Requests = lazy(() => import('../pages/Requests'));
const EditRequest = lazy(() => import('../pages/Requests/EditRequest'));

const Contacts = lazy(() => import('../pages/Contacts'));
const ViewContact = lazy(() => import('../pages/Contacts/ContactView'));
const EditContact = lazy(() => import('../pages/Contacts/EditContact'));

const Tasks = lazy(() => import('../pages/Tasks'));
const EditTask = lazy(() => import('../pages/Tasks/EditTask'));

const TaskList = lazy(() => import('../pages/TaskList'));

const IncomingCalls = lazy(() => import('../pages/IncomingCalls'));

const Terms = lazy(() => import('../pages/Terms'));
const EditTerm = lazy(() => import('../pages/Terms/EditTerm'));

const Videos = lazy(() => import('../pages/Videos'));
const EditVideo = lazy(() => import('../pages/Videos/EditVideo'));

//
const AppRouter = () => {
  //
  const { t } = useTranslation();

  //
  const storeCommon = useContext(CommonStore);

  //
  const history = useHistory();
  const location = useLocation();

  //
  const [, , removeCookie] = useCookies(['token']);

  //
  const [modalVisible, setModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  //
  const logout = () => {
    storeCommon.setUser(null);
    localStorage.removeItem('token');

    removeCookie('token', {
      path: '/',
    });

    history.replace('/login');
  };

  //
  useEffect(() => {
    if (storeCommon.user?.status === AgentStatus.STATUS_PENDING) {
      setModalMessage(
        'The agency must set your status as active so that you can access the application'
      );
      setModalVisible(true);
      return;
    }

    if (
      storeCommon.user?.agency?.next_payment &&
      storeCommon.user?.agency?.trial
    ) {
      const daysBeforePaymentDate = Math.floor(
        (new Date(storeCommon.user.agency.next_payment as string).getTime() -
          new Date().getTime()) /
          86400000
      );

      const route = location.pathname.split('/')[1];

      if (
        daysBeforePaymentDate < 0 &&
        route !== 'agents' &&
        !storeCommon.user?.is_admin
      ) {
        setModalMessage(
          'Payment for the application is not completed or expired'
        );
        setModalVisible(true);
      }
    }
  }, [
    location.pathname,
    storeCommon.user?.agency?.next_payment,
    storeCommon.user?.status,
  ]);

  //
  return (
    <Suspense
      fallback={
        <div
          style={{
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spin size="large" />
        </div>
      }
    >
      <Switch>
        <Route path="/" exact render={() => <Dashboard />} />
        {/**
        <Route path='/admins' exact render={() => <Admins />} />
        <Route path='/admins/:adminId' exact render={() => <EditAdmin />} />
         *
         */}
        <Route path="/agents" exact render={() => <Agents />} />
        <Route path="/agents/create" exact render={() => <EditAgent />} />
        <Route path="/agents/:agentId" exact render={() => <EditAgent />} />
        {/**
         *
         */}
        {storeCommon.user?.role === AgentRole.ROLE_ADMIN && (
          <Route path="/agencies" exact render={() => <Agencies />} />
        )}
        {storeCommon.user?.role === AgentRole.ROLE_ADMIN && (
          <Route path="/payments" exact render={() => <Payments />} />
        )}
        {/* <Route path="/agencies/create" exact render={() => <EditAgency />} /> */}
        {storeCommon.user?.role === AgentRole.ROLE_ADMIN && (
          <Route
            path="/agencies/:agencyId"
            exact
            render={() => <EditAgency />}
          />
        )}
        {/**
         *
         */}
        <Route path="/properties" exact render={() => <Properties />} />
        <Route
          path="/properties/create"
          exact
          render={() => <EditProperty />}
        />
        <Route
          path="/properties/create/:contactId"
          exact
          render={() => <EditProperty />}
        />
        <Route
          path="/properties/edit/:propertyId"
          exact
          render={() => <EditProperty />}
        />
        <Route
          path="/properties/:propertyId"
          exact
          render={() => <ViewProperty />}
        />
        {/**
         *
         */}
        <Route path="/requests" exact render={() => <Requests />} />
        <Route path="/requests/create" exact render={() => <EditRequest />} />
        <Route
          path="/requests/create/:contactId"
          exact
          render={() => <EditRequest />}
        />
        <Route
          path="/requests/:requestId"
          exact
          render={() => <EditRequest />}
        />
        {/**
         *
         */}
        <Route path="/contacts" exact render={() => <Contacts />} />
        <Route path="/contacts/create" exact render={() => <EditContact />} />
        <Route
          path="/contacts/create/:phoneNumber"
          exact
          render={() => <EditContact />}
        />
        <Route
          path="/contacts/:contactId"
          exact
          render={() => <ViewContact />}
        />
        <Route
          path="/contacts/edit/:contactId"
          exact
          render={() => <EditContact />}
        />
        {/**
         *
         */}
        <Route path="/tasks" exact render={() => <Tasks />} />
        <Route path="/tasks/create" exact render={() => <EditTask />} />
        <Route path="/tasks/:taskId" exact render={() => <EditTask />} />
        {/**
         *
         */}
        <Route
          path="/task-list/:tasksStatus"
          exact
          render={() => <TaskList />}
        />
        {/**
         *
         */}
        <Route path="/incomingCalls" exact render={() => <IncomingCalls />} />
        {/**
         *
         */}
        <Route path="/terms" exact render={() => <Terms />} />
        <Route path="/terms/create" exact render={() => <EditTerm />} />
        <Route path="/terms/:termId" exact render={() => <EditTerm />} />
        {/**
         *
         */}
        <Route path="/videos" exact render={() => <Videos />} />
        <Route path="/videos/create" exact render={() => <EditVideo />} />
        <Route path="/videos/:videoId" exact render={() => <EditVideo />} />
        {/**
         *
         */}
        <Route render={() => <Result status="404" title="404" />} />;
      </Switch>

      <Modal
        visible={modalVisible}
        closable={false}
        title={'No access'}
        footer={
          <>
            {storeCommon.user?.role === AgentRole.ROLE_OWNER && (
              <Button
                onClick={() => {
                  setModalVisible(false);
                  history.push(`/agents/${storeCommon.user?.id}`);
                }}
              >
                {t('Subscription')}
              </Button>
            )}
            <Button onClick={logout}>{t('Log out')}</Button>
          </>
        }
      >
        <p>{modalMessage}</p>
      </Modal>
    </Suspense>
  );
};

export default AppRouter;
