import { action, observable, makeObservable } from 'mobx';
import { createContext } from 'react';
import api from '../api/api';
import { User } from '../types';

class CommonStore {
  token: string | undefined = undefined;
  user: User | null = null;
  isOwner: boolean = false;
  loading: boolean = false;

  constructor(token: string | undefined, user: User | null) {
    makeObservable(this, {
      // token: observable,
      user: observable,
      loading: observable,
      setUser: action,
      setLoading: action,
    });

    this.token = token;
    this.user = user;

    // this.setInitValues();
  }

  setInitValues = async () => {
    const token = localStorage.getItem('token');
    if (!token) return;

    //
    const user = await api('admins/me');
    if (user?.id) {
      this.user = user;
    }
  };

  // setToken = (token: string) => {
  //   this.token = token;
  // };

  setUser = (user: User | null) => {
    this.user = user;
    this.isOwner = this.user?.id === this.user?.agency?.owner_id;
  };

  setLoading = (loading: boolean) => {
    this.loading = loading;
  };
}

export default createContext(new CommonStore(undefined, null));
