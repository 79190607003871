import {Avatar, Button, Card, Layout, Popconfirm, Switch} from 'antd';
import {useContext, useState} from 'react';
import {LogoutOutlined, BulbOutlined, BulbFilled} from '@ant-design/icons';
import {Link, useHistory, useLocation} from 'react-router-dom';
import logo from '../../assets/img/logo.png';
import logoWhite from '../../assets/img/logo.png';
import CommonStore from '../../store/CommonStore';
import {SiderTheme} from 'antd/lib/layout/Sider';
import {useTranslation} from 'react-i18next';
import {useCookies} from 'react-cookie';
import AppRouter from '../../router';
import NavigationBar from './NavigationBar';
import {AgentRole} from '../../types';

const {Sider, Content} = Layout;

const MainLayout = () => {
  const {t} = useTranslation();

  //
  const storeCommon = useContext(CommonStore);

  //
  const history = useHistory();

  //
  const [, , removeCookie] = useCookies(['token']);

  const agency = storeCommon.user?.agency;

  //
  const [theme, setTheme] = useState<SiderTheme>(
    (localStorage.getItem('theme') || 'dark') as SiderTheme,
  );

  //
  const logout = () => {
    storeCommon.setUser(null);
    localStorage.removeItem('token');

    removeCookie('token', {
      path: '/',
    });

    history.replace('/login');
  };

  return (
    <Layout>
      <Sider width={256} theme={theme} className='sd-mainLayout'>
        <Link to='/'>
          <img
            src={theme === 'dark' ? logoWhite : logo}
            alt='logo'
            className='sd-mainLayout__logo'
          />
        </Link>
        {!!agency && storeCommon.user?.role === AgentRole.ROLE_OWNER && (
          <Link
            to={`/agents/${storeCommon.user?.id}`}
            className='sd-mainLayout__userLink'>
            <Avatar
              size={60}
              src={`${(agency?.logo || '').replace('-api', '')}`}
            />
            {agency?.title}
          </Link>
        )}
        {storeCommon.user?.role === AgentRole.ROLE_AGENT && (
          <Link
            to={`/agents/${storeCommon.user?.id}`}
            className='sd-mainLayout__userLink'>
            <Avatar
              size={60}
              src={`${(storeCommon.user?.avatar || '').replace('-api', '')}`}
            />
            {storeCommon.user?.lname + '  ' + storeCommon.user?.fname}
          </Link>
        )}
        {storeCommon.user?.role === AgentRole.ROLE_ADMIN && (
          <Link
            to={`/agents/${storeCommon.user?.id}`}
            className='sd-mainLayout__userLink'>
            <Avatar
              size={60}
              src={`${(storeCommon.user?.avatar || '').replace('-api', '')}`}
            />
            {'Administrator'}
          </Link>
        )}

        <>
          <NavigationBar theme={theme} />

          <Card
            style={{width: '100%', marginTop: 'auto'}}
            className={`sd-mainLayout__card ${theme !== 'dark' ? 'light' : ''}`}
            actions={[
              <Switch
                checkedChildren={<BulbFilled />}
                unCheckedChildren={<BulbOutlined />}
                defaultChecked={theme !== 'dark'}
                onChange={value => {
                  setTheme(value ? 'light' : 'dark');
                  localStorage.setItem('theme', value ? 'light' : 'dark');
                }}
              />,
              <Popconfirm
                placement='top'
                title={t('Are you sure?')}
                onConfirm={logout}
                okText={t('Yes')}
                cancelText={t('No')}>
                <Button
                  size='small'
                  type='primary'
                  shape='round'
                  icon={<LogoutOutlined />}
                />
              </Popconfirm>,
            ]}>
            <Card.Meta
              avatar={<Avatar src={storeCommon.user?.avatar || ''} />}
              title={storeCommon.user?.fname}
              description={storeCommon.user?.phone}
            />
          </Card>
        </>
      </Sider>
      <Content className='sd-content'>
        <AppRouter />
      </Content>
    </Layout>
  );
};

export default MainLayout;
