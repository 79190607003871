import { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';

const Login = lazy(() => import('../pages/Login'));
const ForgotPassword = lazy(() => import('../pages/ForgotPassword'));
const ResetPassword = lazy(() => import('../pages/ResetPassword'));

// const SignUp = lazy(() => import('../pages/SignUp'));
// const Invite = lazy(() => import('../pages/Invite'));

const GuestRouter = () => {
  return (
    <Suspense fallback={null}>
      <Switch>
        <Route path='/login' exact render={() => <Login />} />
        <Route
          path='/forgot-password'
          exact
          render={() => <ForgotPassword />}
        />
        <Route
          path='/reset-password/:token'
          exact
          render={() => <ResetPassword />}
        />
        ;
        {/* <Route path='/sign-up' exact render={() => <SignUp />} />
      <Route path='/invite/:token' exact render={() => <Invite />} />; */}
        <Route render={() => <Login />} />;
      </Switch>
    </Suspense>
  );
};

export default GuestRouter;
